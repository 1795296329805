import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { ctaBg } from "../assets";
import { Button } from "@mui/material";
import { BsDot } from "react-icons/bs";

function Copyright() {
  return (
    <Typography variant="body1">
      {"Copyright © "}
      playcosbet {new Date().getFullYear()}
      {"."} <br />
      The Services are virtual pokies-style entertainment games, and do not
      involve any real money bets or prizes.
    </Typography>
  );
}

function PlayResponsible() {
  return (
    <Typography variant="body1" textTransform="uppercase">
      Does not involve the use of Real Money. <br />
      Practicing casino gaming for fun does not imply future success in real
      money gambling. <br />
      This website is intended for persons over 18 years of age and is for entertainment
      purposes only.{" "}
    </Typography>
  );
}

export default function StickyFooter() {
  return (
    <Box
      component="footer"
      sx={{
        px: 2,
        mt: "auto",
        borderTop: "1px solid #1f2031",
        backgroundColor: "primary.main400",
      }}
    >
      <Container
        maxWidth="xl"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          padding: "2rem",
        }}
      >
        <div>
          <Button color="inherit" component={Link} to="/contacts">
            Contacts
          </Button>
        </div>
        <Typography variant="body1">
          <Link
            to="/terms"
            style={{ color: "#ffffff", textDecoration: "none" }}
          >
            Terms
          </Link>{" "}
          <BsDot style={{ verticalAlign: "middle" }} />{" "}
          <Link
            to="/privacy"
            style={{ color: "#ffffff", textDecoration: "none" }}
          >
            Privacy Policy
          </Link>
        </Typography>
        {/* <Copyright /> */}
      </Container>
      <Box
        maxWidth="md"
        mx="auto"
        style={{
          textAlign: "center",
          borderTop: ".1px solid #000000",
          padding: "2rem 0",
        }}
      >
        <PlayResponsible />
      </Box>
    </Box>
  );
}
