// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <div sx={{ backgroundColor: "primary.dark" }}>
      <Container maxWidth="lg">
        {/* Text Block */}
        <Grow in={true} timeout={1200} appear={true}>
          <Box mt={3}>
            <Typography variant="h4" paragraph>
            Are you seeking an immersive gaming experience without the pressure of real money or the thrill of winning? Look no further! Our website offers a vast collection of free online games that are designed for pure fun and entertainment.
            </Typography>
            <Typography variant="body1" paragraph>
            Unlike casinos or gambling websites, our games do not involve the use of real money. This means you can enjoy hours of gameplay without any financial risks or obligations. You're free to explore various genres and find games that truly spark your interest.
            </Typography>
            <Typography variant="body1" paragraph>
            For those who are curious about casino games but hesitate to risk their hard-earned cash, our platform provides an ideal environment to practice and improve your skills. You can experience the excitement of blackjack, roulette, slots, and other popular casino games without the pressure of losing actual money.
            </Typography>
            <Typography variant="body1" paragraph>
            Our games are intended for persons over 18 years of age. By accessing our website, you acknowledge that you meet this age requirement and that the games are solely for entertainment purposes.
            </Typography>
            <Typography variant="h4">
            Responsible Gaming Reminder
                        </Typography>
            <Typography variant="body1" paragraph>
            Practicing casino gaming for fun does not imply future success in real money gambling. It's important to remember that gambling involves risks, and excessive or uncontrolled gambling can have negative consequences. Always gamble responsibly and within your means.
            </Typography>
            <Typography variant="h4">
            Dive into the Fun Today                        </Typography>
            <Typography variant="body1" paragraph>
            Browse our ever-growing library of free online games and let the entertainment begin. From classic card games to immersive action adventures, there's something for every taste. Join us and immerse yourself in a world of gaming without the financial burdens or gambling risks.
            </Typography>
            <Button
              variant="contained"
              sx={{backgroundColor: "secondary"}}
              component={Link}
              to="/games"
            >
                GO TO GAMES
            </Button>


           
          </Box>
        </Grow>
      </Container>
    </div>
  );
}

export default CTA;
