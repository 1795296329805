import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function About() {
  return (
    <div className="about-page" style={{ padding: "0 1rem" }}>
      <Container maxWidth="lg" style={{ margin: "auto" }}>
        <Typography variant="h4" gutterBottom>
          Welcome to playcosbet.com, where our mission is to offer safe and free
          entertainment accessible to a wide majority of people. We believe in
          the power of play and its ability to bring joy, foster community, and
          bridge gaps between individuals from all walks of life. Our platform
          is designed with the vision of creating a space where everyone,
          regardless of age, background, or interests, can find something that
          resonates with them.
        </Typography>

        <Typography variant="h5">
          Our diverse collection of games ensures that there's something for
          everyone. Whether you're in the mood for a challenging puzzle, an
          immersive role-playing adventure, or a casual game to unwind with,
          you'll find it here. We're constantly updating our library with new
          titles to keep the experience fresh and exciting. Our games are not
          only a source of entertainment but also a means of learning,
          creativity, and personal growth.
        </Typography>
      </Container>
    </div>
  );
}
