import React from "react";
import { topGames } from "../data/games";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent"; // Import CardContent
import Button from "@mui/material/Button"; // Import Button
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function TopGames() {
  return (
    <Container maxWidth="lg">
      <Typography textAlign="center" gutterBottom variant="h3" fontWeight="900">
        Top picks
      </Typography>
      <Typography textAlign="center" gutterBottom variant="h5">
        The most popular games among users
      </Typography>

      <Grid container spacing={3}>
        {topGames.map((game) => (
          <Grid item xs={12} sm={4} key={game.id}>
            <Card
              sx={{
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
                backgroundColor: "primary.main400",
                color: "#ffffff",
              }}
            >
              <CardActionArea 
              component={Link}
                to={`/game/${game.id}`}
                style={{ position: "relative" }}
              >
                <svg
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    width: "50px",
                    height: "50px",
                    zIndex: "10",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="red"
                    stroke="orange"
                    strokeWidth=".5px"
                    d="M17.66 11.2c-.23-.3-.51-.56-.77-.82c-.67-.6-1.43-1.03-2.07-1.66C13.33 7.26 13 4.85 13.95 3c-.95.23-1.78.75-2.49 1.32c-2.59 2.08-3.61 5.75-2.39 8.9c.04.1.08.2.08.33c0 .22-.15.42-.35.5c-.23.1-.47.04-.66-.12a.6.6 0 0 1-.14-.17c-1.13-1.43-1.31-3.48-.55-5.12C5.78 10 4.87 12.3 5 14.47c.06.5.12 1 .29 1.5c.14.6.41 1.2.71 1.73c1.08 1.73 2.95 2.97 4.96 3.22c2.14.27 4.43-.12 6.07-1.6c1.83-1.66 2.47-4.32 1.53-6.6l-.13-.26c-.21-.46-.77-1.26-.77-1.26m-3.16 6.3c-.28.24-.74.5-1.1.6c-1.12.4-2.24-.16-2.9-.82c1.19-.28 1.9-1.16 2.11-2.05c.17-.8-.15-1.46-.28-2.23c-.12-.74-.1-1.37.17-2.06c.19.38.39.76.63 1.06c.77 1 1.98 1.44 2.24 2.8c.04.14.06.28.06.43c.03.82-.33 1.72-.93 2.27"
                  />
                </svg>
                <CardMedia
                  component="img"
                  alt={game.title}
                  height="200"
                  image={game.image}
                  title={game.title}
                  style={{ fontSize: "14px" }}
                />
                <CardContent textAlign="center">
                  <Typography variant="h6" component="div" gutterBottom>
                    {game.title}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    // component={Link}
                    // to={`/game/${game.id}`}
                    mx="auto"
                  >
                    Play now
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        sx={{display: "block", textAlign: "center", margin: "3rem auto 0", backgroundColor: "primary.main400"}}
        component={Link}
        to="/games"
      >
        See all games
      </Button>
    </Container>
  );
}
