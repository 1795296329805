import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Form from "./Form";
import { Link } from "react-router-dom";
import { TbPhoneCall } from "react-icons/tb";
import { TbLocation } from "react-icons/tb";

import { TbMail } from "react-icons/tb";

export default function Contacts() {
  return (
    <div style={{ padding: "0 1rem" }}>
      <Container maxWidth="lg" id="contacts">
        <Typography variant="h2" gutterBottom>
          Contact information{" "}
        </Typography>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box>
            <Typography variant="h4" gutterBottom>
              <TbPhoneCall /> +18282155508
            </Typography>
            <Typography variant="h4" gutterBottom>
              <TbLocation /> 850 S Vía Lata Suite 117, Colton, CA 92324, United States
            </Typography>
            <Typography variant="h4" gutterBottom>
              <TbMail /> info@playcosbet.gmail{" "}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom style={{textAlign: "center"}}>
            Have any suggestions or questions?

            </Typography>
            <Form />
          </Box>
        </Box>
      </Container>
    </div>
  );
}
